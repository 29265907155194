<template>
  <div>
    <!--begin::Documents-->
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Organizational Contact List</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools" v-if="user_permissions.includes('chapter_add_contact_list')">






            <div class="my-2">
              <!-- <button
                type="button"
                class="v-btn v-btn--contained theme--dark v-size--default primary"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToCreateContact()"
                >
                  Add Contact
                </span>
              </button> -->
              <v-row justify="center">
                <a
                  href="#"
                  @click="redirectToCreateContact()"
                  class="btn btn-primary font-weight-bolder mr-5"
                >
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,
                            10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  Add Contact
                </a>
                <v-dialog v-model="dialog" persistent max-width="600px">
                  <template v-slot:activator="{ on }">
                    <button
                      type="button"
                      class="btn btn-light-primary font-weight-bolder"
                      style="height: 40px;"
                      dark
                      v-on="on"
                    >
                      <span class="v-btn__content">
                        <i
                          style="/! color: #fff; /margin-right: 5px;font-weight: bold;"
                          class="flaticon2-file-1"
                        >
                        </i>
                        Import
                      </span>
                    </button>
                  </template>
                  <!-- IMPORT POPUP CARD -->
                  <v-card>
                    <v-card-title>
                      <span class="headline">Import Contact</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-file-input
                              show-size
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              label="Conatct list file"
                              placeholder="Select your files"
                              ref="file"
                              :rules="fileRules"
                              outlined
                              dense
                              v-model="contact_file"
                            >
                            </v-file-input>
                          </v-col>
                        </v-row>
                      </v-container>
                      <!-- <small>*indicates required field</small> -->
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="blue darken-1" text @click="downloadSample">
                        Download Sample
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="disable_import"
                        @click="importContact()"
                      >
                        Import
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- IMPORT POPUP CARD -->
                </v-dialog>
              </v-row>
              <!-- <button
                type="button"
                class="v-btn v-btn--contained theme--dark v-size--default primary"
              >
                <span class="v-btn__content" @click="importContact()">
                  Import Contact
                </span>
              </button> -->
            </div>





          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_ba_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <v-spacer></v-spacer>
                <div class="col-md-4 my-2 my-md-0">
                  <div class="input-icon">
                    <v-text-field
                      v-model="search"
                      single-line
                      placeholder="Search"
                      class="form-control"
                      hide-details
                    ></v-text-field>
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="contact_list"
                :search="search"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.first_name }} {{ row.item.last_name }}</td>
                    <td>{{ row.item.title }}</td>
                    <td>{{ row.item.organization }}</td>
                    <td>
                      <v-tooltip top v-if="user_permissions.includes('chapter_add_contact_list')">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="contactEdit(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Contact</span>
                      </v-tooltip>
                      <v-tooltip top v-if="user_permissions.includes('chapter_add_contact_list')">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="contactDelete(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Contact</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Documents-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DELETE_CONTACT } from "@/core/services/store/contact.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ContactList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      user_permissions: [],
      contact_list: [],
      search: "",
      dialog: false,
      contact_file:{},
      disable_import: false,
      fileRules: [
        value =>
          !value ||
          value.size < 5000000 ||
          "File size should be less than 5 MB!"
      ],
      headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Title", value: "title", width: "25%" },
        { text: "Organization Company", value: "organization", width: "25%" },
        { text: "Action", sortable: false, value: "action", width: "25%" }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getContactsList","getUserPermissions"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contacts" }]);
    this.getContactList();
    this.$store.dispatch(GET_COMPANY);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  watch: {
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  methods: {
    downloadSample() {
      window.open("/media/sample/SampleContactImport.xlsx");
    },
    contactDelete(contact_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Contact details!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_CONTACT, contact_id).then(() => {
            this.getContactList();
          });
        } else if (result.isDismissed) {
          Swal.fire("Contact is safe.", "", "info");
        }
      });
    },
    redirectToCreateContact() {
      this.$router.push({ name: "ContactCreate" });
    },
    getContactList() {
      let context = this;
      axios({
        method: "get",
        url: "contacts",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.contact_list = result.data.contacts;
        },
        function() {
          Swal.fire("Error", "Error in fetching Contacts list", "info");
        }
      );
    },
    contactEdit(contact_id) {
      this.$router.push({
        name: "contact.edit",
        params: { id: contact_id }
      });
    },
    importContact() {
      let context = this;
      context.disable_import = true;
      var formData = new FormData();
      formData.append("sample_file", context.contact_file);
      axios
        .post("import-contacts", formData)
        .then(result => {
          if (result.data.error) {
            Swal.fire("Error", result.data.error[0], "error");
          } else {
            Swal.fire("Saved", "Contact list imported successfully", "success");
          }
        })
        .then(() => {
          context.getContactList();
          context.contact_file = "";
          context.disable_import = false;
          context.dialog = false;
        });
    }
  }
};
</script>
